/* eslint-disable */
export const city_list = {

    provinces: [
        {
            children: [
                {
                    text: "石家庄市"
                },
                {
                    text: "邯郸市"
                },
                {
                    text: "唐山市"
                },
                {
                    text: "保定市"
                },
                {
                    text: "秦皇岛市"
                },
                {
                    text: "邢台市"
                },
                {
                    text: "张家口市"
                },
                {
                    text: "承德市"
                },
                {
                    text: "沧州市"
                },
                {
                    text: "廊坊市"
                },
                {
                    text: "衡水市"
                },
                {
                    text: "辛集市"
                },
                {
                    text: "晋州市"
                },
                {
                    text: "新乐市"
                },
                {
                    text: "遵化市"
                },
                {
                    text: "迁安市"
                },
                {
                    text: "霸州市"
                },
                {
                    text: "三河市"
                },
                {
                    text: "定州市"
                },
                {
                    text: "涿州市"
                },
                {
                    text: "安国市"
                },
                {
                    text: "高碑店市"
                },
                {
                    text: "泊头市"
                },
                {
                    text: "任丘市"
                },
                {
                    text: "黄骅市"
                },
                {
                    text: "河间市"
                },
                {
                    text: "冀州市"
                },
                {
                    text: "深州市"
                },
                {
                    text: "南宫市"
                },
                {
                    text: "沙河市"
                },
                {
                    text: "武安市"
                }
            ],
            text: "河北省"
        },
        {
            children: [
                {
                    text: "太原市"
                },
                {
                    text: "大同市"
                },
                {
                    text: "朔州市"
                },
                {
                    text: "阳泉市"
                },
                {
                    text: "长治市"
                },
                {
                    text: "晋城市"
                },
                {
                    text: "忻州市"
                },
                {
                    text: "吕梁市"
                },
                {
                    text: "晋中市"
                },
                {
                    text: "临汾市"
                },
                {
                    text: "运城市"
                },
                {
                    text: "古交市"
                },
                {
                    text: "潞城市"
                },
                {
                    text: "高平市"
                },
                {
                    text: "原平市"
                },
                {
                    text: "孝义市"
                },
                {
                    text: "汾阳市"
                },
                {
                    text: "介休市"
                },
                {
                    text: "侯马市"
                },
                {
                    text: "霍州市"
                },
                {
                    text: "永济市"
                },
                {
                    text: "河津市"
                }
            ],
            text: "山西省"
        },
        {
            children: [
                {
                    text: "呼和浩特市"
                },
                {
                    text: "包头市"
                },
                {
                    text: "乌海市"
                },
                {
                    text: "赤峰市"
                },
                {
                    text: "呼伦贝尔市"
                },
                {
                    text: "通辽市"
                },
                {
                    text: "乌兰察布市"
                },
                {
                    text: "鄂尔多斯市"
                },
                {
                    text: "巴彦淖尔市"
                },
                {
                    text: "满洲里市"
                },
                {
                    text: "扎兰屯市"
                },
                {
                    text: "牙克石市"
                },
                {
                    text: "根河市"
                },
                {
                    text: "额尔古纳市"
                },
                {
                    text: "乌兰浩特市"
                },
                {
                    text: "阿尔山市"
                },
                {
                    text: "霍林郭勒市"
                },
                {
                    text: "锡林浩特市"
                },
                {
                    text: "二连浩特市"
                },
                {
                    text: "丰镇市"
                }
            ],
            text: "内蒙古自治区"
        },
        {
            children: [
                {
                    text: "沈阳市"
                },
                {
                    text: "大连市"
                },
                {
                    text: "朝阳市"
                },
                {
                    text: "阜新市"
                },
                {
                    text: "铁岭市"
                },
                {
                    text: "抚顺市"
                },
                {
                    text: "本溪市"
                },
                {
                    text: "辽阳市"
                },
                {
                    text: "鞍山市"
                },
                {
                    text: "丹东市"
                },
                {
                    text: "营口市"
                },
                {
                    text: "盘锦市"
                },
                {
                    text: "锦州市"
                },
                {
                    text: "葫芦岛市"
                },
                {
                    text: "新民市"
                },
                {
                    text: "瓦房店市"
                },
                {
                    text: "庄河市"
                },
                {
                    text: "北票市"
                },
                {
                    text: "凌源市"
                },
                {
                    text: "调兵山市"
                },
                {
                    text: "开原市"
                },
                {
                    text: "灯塔市"
                },
                {
                    text: "海城市"
                },
                {
                    text: "凤城市"
                },
                {
                    text: "东港市"
                },
                {
                    text: "大石桥市"
                },
                {
                    text: "盖州市"
                },
                {
                    text: "凌海市"
                },
                {
                    text: "北镇市"
                },
                {
                    text: "兴城市"
                }
            ],
            text: "辽宁省"
        },
        {
            children: [
                {
                    text: "长春市"
                },
                {
                    text: "吉林市"
                },
                {
                    text: "白城市"
                },
                {
                    text: "松原市"
                },
                {
                    text: "四平市"
                },
                {
                    text: "辽源市"
                },
                {
                    text: "通化市"
                },
                {
                    text: "白山市"
                },
                {
                    text: "德惠市"
                },
                {
                    text: "榆树市"
                },
                {
                    text: "磐石市"
                },
                {
                    text: "蛟河市"
                },
                {
                    text: "桦甸市"
                },
                {
                    text: "舒兰市"
                },
                {
                    text: "洮南市"
                },
                {
                    text: "大安市"
                },
                {
                    text: "双辽市"
                },
                {
                    text: "公主岭市"
                },
                {
                    text: "梅河口市"
                },
                {
                    text: "集安市"
                },
                {
                    text: "临江市"
                },
                {
                    text: "延吉市"
                },
                {
                    text: "图们市"
                },
                {
                    text: "敦化市"
                },
                {
                    text: "珲春市"
                },
                {
                    text: "龙井市"
                },
                {
                    text: "和龙市"
                },
                {
                    text: "扶余市"
                }
            ],
            text: "吉林省"
        },
        {
            children: [
                {
                    text: "哈尔滨市"
                },
                {
                    text: "齐齐哈尔市"
                },
                {
                    text: "黑河市"
                },
                {
                    text: "大庆市"
                },
                {
                    text: "伊春市"
                },
                {
                    text: "鹤岗市"
                },
                {
                    text: "佳木斯市"
                },
                {
                    text: "双鸭山市"
                },
                {
                    text: "七台河市"
                },
                {
                    text: "鸡西市"
                },
                {
                    text: "牡丹江市"
                },
                {
                    text: "绥化市"
                },
                {
                    text: "尚志市"
                },
                {
                    text: "五常市"
                },
                {
                    text: "讷河市"
                },
                {
                    text: "北安市"
                },
                {
                    text: "五大连池市"
                },
                {
                    text: "铁力市"
                },
                {
                    text: "同江市"
                },
                {
                    text: "富锦市"
                },
                {
                    text: "虎林市"
                },
                {
                    text: "密山市"
                },
                {
                    text: "绥芬河市"
                },
                {
                    text: "海林市"
                },
                {
                    text: "宁安市"
                },
                {
                    text: "安达市"
                },
                {
                    text: "肇东市"
                },
                {
                    text: "海伦市"
                },
                {
                    text: "穆棱市"
                },
                {
                    text: "东宁市"
                },
                {
                    text: "抚远市"
                }
            ],
            text: "黑龙江省"
        },
        {
            children: [
                {
                    text: "南京市"
                },
                {
                    text: "徐州市"
                },
                {
                    text: "连云港市"
                },
                {
                    text: "宿迁市"
                },
                {
                    text: "淮安市"
                },
                {
                    text: "盐城市"
                },
                {
                    text: "扬州市"
                },
                {
                    text: "泰州市"
                },
                {
                    text: "南通市"
                },
                {
                    text: "镇江市"
                },
                {
                    text: "常州市"
                },
                {
                    text: "无锡市"
                },
                {
                    text: "苏州市"
                },
                {
                    text: "常熟市"
                },
                {
                    text: "张家港市"
                },
                {
                    text: "太仓市"
                },
                {
                    text: "昆山市"
                },
                {
                    text: "江阴市"
                },
                {
                    text: "宜兴市"
                },
                {
                    text: "溧阳市"
                },
                {
                    text: "扬中市"
                },
                {
                    text: "句容市"
                },
                {
                    text: "丹阳市"
                },
                {
                    text: "如皋市"
                },
                {
                    text: "海门市"
                },
                {
                    text: "启东市"
                },
                {
                    text: "高邮市"
                },
                {
                    text: "仪征市"
                },
                {
                    text: "兴化市"
                },
                {
                    text: "泰兴市"
                },
                {
                    text: "靖江市"
                },
                {
                    text: "东台市"
                },
                {
                    text: "邳州市"
                },
                {
                    text: "新沂市"
                }
            ],
            text: "江苏省"
        },
        {
            children: [
                {
                    text: "杭州市"
                },
                {
                    text: "宁波市"
                },
                {
                    text: "湖州市"
                },
                {
                    text: "嘉兴市"
                },
                {
                    text: "舟山市"
                },
                {
                    text: "绍兴市"
                },
                {
                    text: "衢州市"
                },
                {
                    text: "金华市"
                },
                {
                    text: "台州市"
                },
                {
                    text: "温州市"
                },
                {
                    text: "丽水市"
                },
                {
                    text: "临安市"
                },
                {
                    text: "建德市"
                },
                {
                    text: "慈溪市"
                },
                {
                    text: "余姚市"
                },
                {
                    text: "平湖市"
                },
                {
                    text: "海宁市"
                },
                {
                    text: "桐乡市"
                },
                {
                    text: "诸暨市"
                },
                {
                    text: "嵊州市"
                },
                {
                    text: "江山市"
                },
                {
                    text: "兰溪市"
                },
                {
                    text: "永康市"
                },
                {
                    text: "义乌市"
                },
                {
                    text: "东阳市"
                },
                {
                    text: "临海市"
                },
                {
                    text: "温岭市"
                },
                {
                    text: "瑞安市"
                },
                {
                    text: "乐清市"
                },
                {
                    text: "龙泉市"
                }
            ],
            text: "浙江省"
        },
        {
            children: [
                {
                    text: "合肥市"
                },
                {
                    text: "芜湖市"
                },
                {
                    text: "蚌埠市"
                },
                {
                    text: "淮南市"
                },
                {
                    text: "马鞍山市"
                },
                {
                    text: "淮北市"
                },
                {
                    text: "铜陵市"
                },
                {
                    text: "安庆市"
                },
                {
                    text: "黄山市"
                },
                {
                    text: "滁州市"
                },
                {
                    text: "阜阳市"
                },
                {
                    text: "宿州市"
                },
                {
                    text: "六安市"
                },
                {
                    text: "亳州市"
                },
                {
                    text: "池州市"
                },
                {
                    text: "宣城市"
                },
                {
                    text: "巢湖市"
                },
                {
                    text: "桐城市"
                },
                {
                    text: "天长市"
                },
                {
                    text: "明光市"
                },
                {
                    text: "界首市"
                },
                {
                    text: "宁国市"
                }
            ],
            text: "安徽省"
        },
        {
            children: [
                {
                    text: "厦门市"
                },
                {
                    text: "福州市"
                },
                {
                    text: "南平市"
                },
                {
                    text: "三明市"
                },
                {
                    text: "莆田市"
                },
                {
                    text: "泉州市"
                },
                {
                    text: "漳州市"
                },
                {
                    text: "龙岩市"
                },
                {
                    text: "宁德市"
                },
                {
                    text: "福清市"
                },
                {
                    text: "长乐市"
                },
                {
                    text: "邵武市"
                },
                {
                    text: "武夷山市"
                },
                {
                    text: "建瓯市"
                },
                {
                    text: "永安市"
                },
                {
                    text: "石狮市"
                },
                {
                    text: "晋江市"
                },
                {
                    text: "南安市"
                },
                {
                    text: "龙海市"
                },
                {
                    text: "漳平市"
                },
                {
                    text: "福安市"
                },
                {
                    text: "福鼎市"
                }
            ],
            text: "福建省"
        },
        {
            children: [
                {
                    text: "南昌市"
                },
                {
                    text: "九江市"
                },
                {
                    text: "景德镇市"
                },
                {
                    text: "鹰潭市"
                },
                {
                    text: "新余市"
                },
                {
                    text: "萍乡市"
                },
                {
                    text: "赣州市"
                },
                {
                    text: "上饶市"
                },
                {
                    text: "抚州市"
                },
                {
                    text: "宜春市"
                },
                {
                    text: "吉安市"
                },
                {
                    text: "瑞昌市"
                },
                {
                    text: "共青城市"
                },
                {
                    text: "乐平市"
                },
                {
                    text: "瑞金市"
                },
                {
                    text: "德兴市"
                },
                {
                    text: "丰城市"
                },
                {
                    text: "樟树市"
                },
                {
                    text: "高安市"
                },
                {
                    text: "井冈山市"
                },
                {
                    text: "贵溪市"
                }
            ],
            text: "江西省"
        },
        {
            children: [
                {
                    text: "济南市"
                },
                {
                    text: "青岛市"
                },
                {
                    text: "聊城市"
                },
                {
                    text: "德州市"
                },
                {
                    text: "东营市"
                },
                {
                    text: "淄博市"
                },
                {
                    text: "潍坊市"
                },
                {
                    text: "烟台市"
                },
                {
                    text: "威海市"
                },
                {
                    text: "日照市"
                },
                {
                    text: "临沂市"
                },
                {
                    text: "枣庄市"
                },
                {
                    text: "济宁市"
                },
                {
                    text: "泰安市"
                },
                {
                    text: "莱芜市"
                },
                {
                    text: "滨州市"
                },
                {
                    text: "菏泽市"
                },
                {
                    text: "胶州市"
                },
                {
                    text: "即墨市"
                },
                {
                    text: "平度市"
                },
                {
                    text: "莱西市"
                },
                {
                    text: "临清市"
                },
                {
                    text: "乐陵市"
                },
                {
                    text: "禹城市"
                },
                {
                    text: "安丘市"
                },
                {
                    text: "昌邑市"
                },
                {
                    text: "高密市"
                },
                {
                    text: "青州市"
                },
                {
                    text: "诸城市"
                },
                {
                    text: "寿光市"
                },
                {
                    text: "栖霞市"
                },
                {
                    text: "海阳市"
                },
                {
                    text: "龙口市"
                },
                {
                    text: "莱阳市"
                },
                {
                    text: "莱州市"
                },
                {
                    text: "蓬莱市"
                },
                {
                    text: "招远市"
                },
                {
                    text: "荣成市"
                },
                {
                    text: "乳山市"
                },
                {
                    text: "滕州市"
                },
                {
                    text: "曲阜市"
                },
                {
                    text: "邹城市"
                },
                {
                    text: "新泰市"
                },
                {
                    text: "肥城市"
                }
            ],
            text: "山东省"
        },
        {
            children: [
                {
                    text: "郑州市"
                },
                {
                    text: "开封市"
                },
                {
                    text: "洛阳市"
                },
                {
                    text: "平顶山市"
                },
                {
                    text: "安阳市"
                },
                {
                    text: "鹤壁市"
                },
                {
                    text: "新乡市"
                },
                {
                    text: "焦作市"
                },
                {
                    text: "濮阳市"
                },
                {
                    text: "许昌市"
                },
                {
                    text: "漯河市"
                },
                {
                    text: "三门峡市"
                },
                {
                    text: "南阳市"
                },
                {
                    text: "商丘市"
                },
                {
                    text: "周口市"
                },
                {
                    text: "驻马店市"
                },
                {
                    text: "信阳市"
                },
                {
                    text: "荥阳市"
                },
                {
                    text: "新郑市"
                },
                {
                    text: "登封市"
                },
                {
                    text: "新密市"
                },
                {
                    text: "偃师市"
                },
                {
                    text: "孟州市"
                },
                {
                    text: "沁阳市"
                },
                {
                    text: "卫辉市"
                },
                {
                    text: "辉县市"
                },
                {
                    text: "林州市"
                },
                {
                    text: "禹州市"
                },
                {
                    text: "长葛市"
                },
                {
                    text: "舞钢市"
                },
                {
                    text: "义马市"
                },
                {
                    text: "灵宝市"
                },
                {
                    text: "项城市"
                },
                {
                    text: "巩义市"
                },
                {
                    text: "邓州市"
                },
                {
                    text: "永城市"
                },
                {
                    text: "汝州市"
                },
                {
                    text: "济源市"
                }
            ],
            text: "河南省"
        },
        {
            children: [
                {
                    text: "武汉市"
                },
                {
                    text: "十堰市"
                },
                {
                    text: "襄阳市"
                },
                {
                    text: "荆门市"
                },
                {
                    text: "孝感市"
                },
                {
                    text: "黄冈市"
                },
                {
                    text: "鄂州市"
                },
                {
                    text: "黄石市"
                },
                {
                    text: "咸宁市"
                },
                {
                    text: "荆州市"
                },
                {
                    text: "宜昌市"
                },
                {
                    text: "随州市"
                },
                {
                    text: "丹江口市"
                },
                {
                    text: "老河口市"
                },
                {
                    text: "枣阳市"
                },
                {
                    text: "宜城市"
                },
                {
                    text: "钟祥市"
                },
                {
                    text: "汉川市"
                },
                {
                    text: "应城市"
                },
                {
                    text: "安陆市"
                },
                {
                    text: "广水市"
                },
                {
                    text: "麻城市"
                },
                {
                    text: "武穴市"
                },
                {
                    text: "大冶市"
                },
                {
                    text: "赤壁市"
                },
                {
                    text: "石首市"
                },
                {
                    text: "洪湖市"
                },
                {
                    text: "松滋市"
                },
                {
                    text: "宜都市"
                },
                {
                    text: "枝江市"
                },
                {
                    text: "当阳市"
                },
                {
                    text: "恩施市"
                },
                {
                    text: "利川市"
                },
                {
                    text: "仙桃市"
                },
                {
                    text: "天门市"
                },
                {
                    text: "潜江市"
                }
            ],
            text: "湖北省"
        },
        {
            children: [
                {
                    text: "长沙市"
                },
                {
                    text: "衡阳市"
                },
                {
                    text: "张家界市"
                },
                {
                    text: "常德市"
                },
                {
                    text: "益阳市"
                },
                {
                    text: "岳阳市"
                },
                {
                    text: "株洲市"
                },
                {
                    text: "湘潭市"
                },
                {
                    text: "郴州市"
                },
                {
                    text: "永州市"
                },
                {
                    text: "邵阳市"
                },
                {
                    text: "怀化市"
                },
                {
                    text: "娄底市"
                },
                {
                    text: "耒阳市"
                },
                {
                    text: "常宁市"
                },
                {
                    text: "浏阳市"
                },
                {
                    text: "津市市"
                },
                {
                    text: "沅江市"
                },
                {
                    text: "汨罗市"
                },
                {
                    text: "临湘市"
                },
                {
                    text: "醴陵市"
                },
                {
                    text: "湘乡市"
                },
                {
                    text: "韶山市"
                },
                {
                    text: "资兴市"
                },
                {
                    text: "武冈市"
                },
                {
                    text: "洪江市"
                },
                {
                    text: "冷水江市"
                },
                {
                    text: "涟源市"
                },
                {
                    text: "吉首市"
                }
            ],
            text: "湖南省"
        },
        {
            children: [
                {
                    text: "广州市"
                },
                {
                    text: "深圳市"
                },
                {
                    text: "清远市"
                },
                {
                    text: "韶关市"
                },
                {
                    text: "河源市"
                },
                {
                    text: "梅州市"
                },
                {
                    text: "潮州市"
                },
                {
                    text: "汕头市"
                },
                {
                    text: "揭阳市"
                },
                {
                    text: "汕尾市"
                },
                {
                    text: "惠州市"
                },
                {
                    text: "东莞市"
                },
                {
                    text: "珠海市"
                },
                {
                    text: "中山市"
                },
                {
                    text: "江门市"
                },
                {
                    text: "佛山市"
                },
                {
                    text: "肇庆市"
                },
                {
                    text: "云浮市"
                },
                {
                    text: "阳江市"
                },
                {
                    text: "茂名市"
                },
                {
                    text: "湛江市"
                },
                {
                    text: "英德市"
                },
                {
                    text: "连州市"
                },
                {
                    text: "乐昌市"
                },
                {
                    text: "南雄市"
                },
                {
                    text: "兴宁市"
                },
                {
                    text: "普宁市"
                },
                {
                    text: "陆丰市"
                },
                {
                    text: "恩平市"
                },
                {
                    text: "台山市"
                },
                {
                    text: "开平市"
                },
                {
                    text: "鹤山市"
                },
                {
                    text: "四会市"
                },
                {
                    text: "罗定市"
                },
                {
                    text: "阳春市"
                },
                {
                    text: "化州市"
                },
                {
                    text: "信宜市"
                },
                {
                    text: "高州市"
                },
                {
                    text: "吴川市"
                },
                {
                    text: "廉江市"
                },
                {
                    text: "雷州市"
                }
            ],
            text: "广东省"
        },
        {
            children: [
                {
                    text: "南宁市"
                },
                {
                    text: "桂林市"
                },
                {
                    text: "柳州市"
                },
                {
                    text: "梧州市"
                },
                {
                    text: "贵港市"
                },
                {
                    text: "玉林市"
                },
                {
                    text: "钦州市"
                },
                {
                    text: "北海市"
                },
                {
                    text: "防城港市"
                },
                {
                    text: "崇左市"
                },
                {
                    text: "百色市"
                },
                {
                    text: "河池市"
                },
                {
                    text: "来宾市"
                },
                {
                    text: "贺州市"
                },
                {
                    text: "岑溪市"
                },
                {
                    text: "桂平市"
                },
                {
                    text: "北流市"
                },
                {
                    text: "东兴市"
                },
                {
                    text: "凭祥市"
                },
                {
                    text: "宜州市"
                },
                {
                    text: "合山市"
                },
                {
                    text: "靖西市"
                }
            ],
            text: "广西壮族自治区"
        },
        {
            children: [
                {
                    text: "海口市"
                },
                {
                    text: "三亚市"
                },
                {
                    text: "三沙市"
                },
                {
                    text: "儋州市"
                },
                {
                    text: "文昌市"
                },
                {
                    text: "琼海市"
                },
                {
                    text: "万宁市"
                },
                {
                    text: "东方市"
                },
                {
                    text: "五指山市"
                }
            ],
            text: "海南省"
        },
        {
            children: [
                {
                    text: "成都市"
                },
                {
                    text: "广元市"
                },
                {
                    text: "绵阳市"
                },
                {
                    text: "德阳市"
                },
                {
                    text: "南充市"
                },
                {
                    text: "广安市"
                },
                {
                    text: "遂宁市"
                },
                {
                    text: "内江市"
                },
                {
                    text: "乐山市"
                },
                {
                    text: "自贡市"
                },
                {
                    text: "泸州市"
                },
                {
                    text: "宜宾市"
                },
                {
                    text: "攀枝花市"
                },
                {
                    text: "巴中市"
                },
                {
                    text: "达州市"
                },
                {
                    text: "资阳市"
                },
                {
                    text: "眉山市"
                },
                {
                    text: "雅安市"
                },
                {
                    text: "崇州市"
                },
                {
                    text: "邛崃市"
                },
                {
                    text: "都江堰市"
                },
                {
                    text: "彭州市"
                },
                {
                    text: "江油市"
                },
                {
                    text: "什邡市"
                },
                {
                    text: "广汉市"
                },
                {
                    text: "绵竹市"
                },
                {
                    text: "阆中市"
                },
                {
                    text: "华蓥市"
                },
                {
                    text: "峨眉山市"
                },
                {
                    text: "万源市"
                },
                {
                    text: "简阳市"
                },
                {
                    text: "西昌市"
                },
                {
                    text: "康定市"
                },
                {
                    text: "马尔康市"
                }
            ],
            text: "四川省"
        },
        {
            children: [
                {
                    text: "贵阳市"
                },
                {
                    text: "六盘水市"
                },
                {
                    text: "遵义市"
                },
                {
                    text: "安顺市"
                },
                {
                    text: "毕节市"
                },
                {
                    text: "铜仁市"
                },
                {
                    text: "清镇市"
                },
                {
                    text: "赤水市"
                },
                {
                    text: "仁怀市"
                },
                {
                    text: "凯里市"
                },
                {
                    text: "都匀市"
                },
                {
                    text: "兴义市"
                },
                {
                    text: "福泉市"
                }
            ],
            text: "贵州省"
        },
        {
            children: [
                {
                    text: "昆明市"
                },
                {
                    text: "曲靖市"
                },
                {
                    text: "玉溪市"
                },
                {
                    text: "丽江市"
                },
                {
                    text: "昭通市"
                },
                {
                    text: "普洱市"
                },
                {
                    text: "临沧市"
                },
                {
                    text: "保山市"
                },
                {
                    text: "安宁市"
                },
                {
                    text: "宣威市"
                },
                {
                    text: "芒市"
                },
                {
                    text: "瑞丽市"
                },
                {
                    text: "大理市"
                },
                {
                    text: "楚雄市"
                },
                {
                    text: "个旧市"
                },
                {
                    text: "开远市"
                },
                {
                    text: "蒙自市"
                },
                {
                    text: "弥勒市"
                },
                {
                    text: "景洪市"
                },
                {
                    text: "文山市"
                },
                {
                    text: "香格里拉市"
                },
                {
                    text: "腾冲市"
                }
            ],
            text: "云南省"
        },
        {
            children: [
                {
                    text: "西安市"
                },
                {
                    text: "延安市"
                },
                {
                    text: "铜川市"
                },
                {
                    text: "渭南市"
                },
                {
                    text: "咸阳市"
                },
                {
                    text: "宝鸡市"
                },
                {
                    text: "汉中市"
                },
                {
                    text: "榆林市"
                },
                {
                    text: "商洛市"
                },
                {
                    text: "安康市"
                },
                {
                    text: "韩城"
                },
                {
                    text: "华阴"
                },
                {
                    text: "兴平"
                }
            ],
            text: "陕西省"
        },
        {
            children: [
                {
                    text: "兰州市"
                },
                {
                    text: "嘉峪关市"
                },
                {
                    text: "金昌市"
                },
                {
                    text: "白银市"
                },
                {
                    text: "天水市"
                },
                {
                    text: "酒泉市"
                },
                {
                    text: "张掖市"
                },
                {
                    text: "武威市"
                },
                {
                    text: "庆阳市"
                },
                {
                    text: "平凉市"
                },
                {
                    text: "定西市"
                },
                {
                    text: "陇南市"
                },
                {
                    text: "玉门市"
                },
                {
                    text: "敦煌市"
                },
                {
                    text: "临夏市"
                },
                {
                    text: "合作市"
                }
            ],
            text: "甘肃省"
        },
        {
            children: [
                {
                    text: "西宁市"
                },
                {
                    text: "海东市"
                },
                {
                    text: "格尔木市"
                },
                {
                    text: "德令哈市"
                },
                {
                    text: "玉树市"
                }
            ],
            text: "青海省"
        },
        {
            children: [
                {
                    text: "拉萨市"
                },
                {
                    text: "日喀则市"
                },
                {
                    text: "昌都市"
                },
                {
                    text: "林芝市"
                },
                {
                    text: "山南市"
                }
            ],
            text: "西藏自治区"
        },
        {
            children: [
                {
                    text: "银川市"
                },
                {
                    text: "石嘴山市"
                },
                {
                    text: "吴忠市"
                },
                {
                    text: "中卫市"
                },
                {
                    text: "固原市"
                },
                {
                    text: "灵武市"
                },
                {
                    text: "青铜峡市"
                }
            ],
            text: "宁夏回族自治区"
        },
        {
            children: [
                {
                    text: "台北市"
                },
                {
                    text: "新北市"
                },
                {
                    text: "桃园市"
                },
                {
                    text: "台中市"
                },
                {
                    text: "台南市"
                },
                {
                    text: "高雄市"
                },
                {
                    text: "基隆市"
                },
                {
                    text: "新竹市"
                },
                {
                    text: "嘉义市"
                }
            ],
            text: "台湾"
        },
        {
            children: [
                {
                    text: "北京市"
                }
            ],
            text: "北京市"
        },
        {
            children: [
                {
                    text: "天津市"
                }
            ],
            text: "天津市"
        },
        {
            children: [
                {
                    text: "上海市"
                }
            ],
            text: "上海市"
        },
        {
            children: [
                {
                    text: "重庆市"
                }
            ],
            text: "重庆市"
        },
        {
            children: [
                {
                    text: "香港特别行政区"
                }
            ],
            text: "香港"
        },
        {
            children: [
                {
                    text: "澳门特别行政区"
                }
            ],
            text: "澳门"
        },
        {
            children: [
                {
                    text: "乌鲁木齐市"
                },
                {
                    text: "克拉玛依市"
                },
                {
                    text: "吐鲁番市"
                },
                {
                    text: "哈密市"
                },
                {
                    text: "喀什市"
                },
                {
                    text: "阿克苏市"
                },
                {
                    text: "和田市"
                },
                {
                    text: "阿图什市"
                },
                {
                    text: "阿拉山口市"
                },
                {
                    text: "博乐市"
                },
                {
                    text: "昌吉市"
                },
                {
                    text: "阜康市"
                },
                {
                    text: "库尔勒市"
                },
                {
                    text: "伊宁市"
                },
                {
                    text: "奎屯市"
                },
                {
                    text: "塔城市"
                },
                {
                    text: "乌苏市"
                },
                {
                    text: "阿勒泰市"
                },
                {
                    text: "霍尔果斯市"
                },
                {
                    text: "石河子市"
                },
                {
                    text: "阿拉尔市"
                },
                {
                    text: "图木舒克市"
                },
                {
                    text: "五家渠市"
                },
                {
                    text: "北屯市"
                },
                {
                    text: "铁门关市"
                },
                {
                    text: "双河市"
                },
                {
                    text: "可克达拉市"
                },
                {
                    text: "昆玉市"
                }
            ],
            text: "新疆维吾尔自治区"
        }
    ]
}



