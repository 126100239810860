<template>
  <div class="g_page_box">
    <div class="g_main_content">
      <g-loading :loading="loading" />
      <div class="out">
        <div class="head">
          <div class="head_out">
              <div class="head_left">
                <input type="text" class="city" @click="showPicker = true" readonly placeholder="请选择城市" v-model="city">
                <van-icon name="arrow-down" size="12px" />
              </div>
              <div class="head_right">
                <van-icon name="search" size="16px" />
                <input type="text" id="tipinput" class="ipt" placeholder="请输入地址" v-model="address">
                <van-icon name="clear" v-if="address.length" size="16px" @click="address=''" />
              </div>
          </div>
        </div>
        <div id="map" class="map"></div>
        <div class="list_box">
          <div class="list" @click="list_click(item, idx)" v-for="(item, idx) in address_list" :key="item.id">
            <div class="left">
              <div class="title">
                <span>{{item.name}}</span>
              </div>
              <div class="sub">{{item.cityname + item.adname + item.address}}</div>
            </div>
            <div class="right">
              <div class="btn"><van-icon name="arrow" /></div>
            </div>
          </div>
        </div>
      </div>
      <van-popup v-model="showPicker" round position="bottom">
        <van-picker
          show-toolbar
          :columns="columns"
          @cancel="showPicker = false"
          @confirm="onConfirm"
        />
      </van-popup>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { isEmptyObj } from '../../utils/validate';
import {city_list} from './config'

export default {
  data() {
    return {
      city:'',
      address: '',
      showPicker: false,
      columns: city_list.provinces,
      amap: null,
      autoOptions: null,
      auto:null,
      placeSearch: null,
      marker: null,
      marker_arr: [],
      address_list: [],
      center: [],
      city_list,
      loading: false,
      id:'',
      order_id:'',
      province:'',
    };
  },
  computed: {
    ...mapState("commonData", {
      geolocation: (state) => state.geolocation,
    }),
  },
  components: {},
  watch : {
    
  },
  created() {
    this.order_id = this.$route.query.order_id
    this.id = this.$route.query.id
    //this.address = this.$route.query.address
    
  },
  mounted(){
    this.init_map()
    this.set_place()
    if(!isEmptyObj(this.geolocation)){
      this.init_list('',[this.geolocation.lng, this.geolocation.lat])
    } else {
      this.init_auto()
    }
     
    
  },
  methods: {
    onConfirm(value) {
      this.province = value[0]
      this.city = value[1];
      this.showPicker = false;
      this.init_auto()
    },
    init_map() {
      this.amap = new AMap.Map("map", {
        resizeEnable: true
      })
    },
    init_auto(){
      this.auto = new AMap.Autocomplete({
        input: "tipinput",
        city: this.city
      })

      let that = this
      AMap.event.addListener(this.auto, "select", function(e) {
        that.address = e.poi.name
        that.placeSearch.setCity(e.poi.adcode);
        // that.placeSearch.search(e.poi.name, function(status, result) {
        //   that.address_list = status === 'complete'? result.poiList.pois : []
        // })
        if(!isEmptyObj(e.poi.location)){
          that.init_list(that.address, [e.poi.location.lng, e.poi.location.lat])
        }
        
        that.amap.setFitView()
      })
    },
    
    set_place() {
      this.placeSearch = new AMap.PlaceSearch({
        map: this.amap,
        //autoOptions: place,
        //type: '餐饮服务', // 兴趣点类别
        pageSize: 10, // 单页显示结果条数
        pageIndex: 1, // 页码
        //city: "010", // 兴趣点城市
        //citylimit: true,  //是否强制限制在设置的城市内搜索
        autoFitView: true 
      })
    },
    
    init_list(keyWord, center) {
      this.loading = true
      const cpoint = center || [116.405467, 39.907761]; 
      let that = this
      this.placeSearch.searchNearBy(keyWord, cpoint, 200, function(status, result) {
        if(status && !isEmptyObj(result)){
          that.address_list = result.poiList.pois
          that.city = result.poiList.pois[0].cityname
          that.province = result.poiList.pois[0].pname
          that.loading = false
          that.init_auto()
        }
      });
    },
    destroyMap() {
      this.amap && this.amap.destroy();
    },

    list_click(item) {
      this.$router.push({ path: "/edit-location", query: { address: this.province+item.cityname+item.adname+item.address, id:this.id, order_id: this.order_id } });
    },
    addMarker(position) {
      this.marker = new AMap.Marker({
        icon: "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png",
        position:position,
        offset: new AMap.Pixel(-13, -30)
      });
      this.marker.setMap(this.amap);
    },
    updateIcon(index) {
      this.marker_arr[index].setIcon('//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png')
    }
    
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.g_page_box {
  background: #f2f7f8;
}

.g_main_content {
  padding-bottom: 0;
}

.out {
  background: #fff;
}

.list {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  border-bottom: solid 1px rgba(221, 221, 221);
  padding: 15px 16px;
}

.left {
  width: 75%;
}

.title {
  font-size: 14px;
  line-height: 20px;
  color: #292929;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;

  & span {
    flex: 1;
  }
}

.sub {
  font-size: 12px;
  color: #919191;
  line-height: 20px;
}

.btn {
  color: #969799;
}

.head {
  padding: 10px 12px;
  height: 54px;
}

.head_out {
  background: #F7F8FA;
  border-radius: 2px;
  height: 34px;
  display: flex;
  align-items: center;
  position: relative;
}

.head_left,
.head_right {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.head_right {
  flex: 1;
  padding-right: 10px;
}

.head_left {
  width: 25%;
  margin-right: 10px;
}


.city,
.ipt {
  width: 100%;
  background: transparent;
  padding: 0 10px;
}

.map {
  height: 410px;
}

.list_box {
  height: calc(100vh - 464px);
  overflow-y: auto;
}

>>>.amap-logo,
>>>.amap-copyright {
  display: none !important;
}

>>> .marker {
      position: absolute;
      top: -20px;
      right: -118px;
      color: #fff;
      padding: 4px 10px;
      box-shadow: 1px 1px 1px rgba(10, 10, 10, .2);
      white-space: nowrap;
      font-size: 12px;
      font-family: "";
      background-color: #25A5F7;
      border-radius: 3px;
    }
</style>
